
/* Script to show fixed navbar */
(function () {

  var navbar = document.querySelector('.site-header');
  var navbar_ = document.querySelector('.site-header-right');
  var _window = window;

  var scrollListener = function (event) {

    if (window.screen.availWidth < 600) {
      var offset = 115;
    } else{
      var offset = 150;
    }

    var offset2 = offset - 60;

    if ( _window.scrollY > offset2 && _window.scrollY < offset ) {
      navbar_.style.marginTop = _window.scrollY - offset + "px";
    } else {
      navbar_.style.marginTop = "0px";
    }

    if ( navbar.classList.contains('inverted') && _window.scrollY > offset ) {
      navbar.classList.remove('inverted');
    }
    else if ( ! navbar.classList.contains('inverted') && _window.scrollY < offset ) {
      navbar.classList.add('inverted');
    }

  };

  _window.addEventListener('scroll', scrollListener);

})();
