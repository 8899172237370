
/**
 * Modal
 * Crea un modal. Soporta varios modals en una misma página.
 *
 * Necesita la siguiente estructura
 * <a href="mymodal" data-model="modal" ..>Open Modal</a>
 * ...
 * <div class="Modal" id="mymodal"> ... </div>
 *
 * @author jonalvarezz
 */
(function (window, document, $) {
  "use strict";

  var htmlDocument = document.documentElement
  var modalList = document.querySelectorAll('[data-model="modal"]');
  var closeBtn = document.createElement('BUTTON');
  closeBtn.className = 'close flat';
  closeBtn.innerHTML = '<i class="fa fa-close"> </i>';

  if ( ! modalList ) return ;

  var getTarget = function (ref) {
    var ref = ref.dataset.target || ref.href.split('#')[1];
    var target = document.getElementById(ref);
    if (! target) {
      throw new Error('El target del modal está mal especificado. Debe ser un #id válido');
    }
    return target;
  };

  var closeModal = function (target) {
    return function (event) {
      event.stopPropagation();
      event.preventDefault();
      target.classList.add('out'),
      target.classList.remove('in');

      htmlDocument.classList.remove('modal-opened');
    }
  };

  var openModal = function (event) {
    event.stopPropagation();
    event.preventDefault();
    var target = getTarget(event.target);
    var btn = null;
    // Append close button if any
    if (! target.querySelector('button.close.flat') ) {
      btn = closeBtn.cloneNode(true);
      btn.addEventListener('click', closeModal(target));
      target.appendChild(btn);
    }
    target.classList.remove('out');
    target.classList.add('in');

    htmlDocument.classList.add('modal-opened');
    $(target).trigger('cannaworks.modal.open')
  };

  for (var i = 0, node; node = modalList[i++];) {
    node.addEventListener('click', openModal);
  }

})(window, document, jQuery);

/**
 * Aplicación para mostrar cualquier información (HTML) luego de que un formulario es
 * enviado.
 *
 * El formulario debe contener los siguientes atributos:
 * - data-hook="postprocess" Indica que aplicará post procesamiento con este script
 * - data-postprocesstarget="HTMLID" Relación elemento HTML con ID HTMLID que mostrará
 *     una vez se envíe el formulario.
 *
 */
!(function (document, window, $) {

  var postProcessForm = document.querySelector('.contact .Formulario');

  if (! postProcessForm) return -1;

  var targetEl = document.getElementById(postProcessForm.dataset.postprocesstarget);

  $(postProcessForm).on("xpage.formularios.done", function(e, data) {
    setTimeout(function()
    {
      document.querySelector('.close.flat').click();
    }, 3000);
  });

})(document, window, jQuery);


!(function (document, window, $) {

  function showEvents(e) {
    select = e.target;
    place_id = select.options[select.options.selectedIndex].value
    post_id = select.options[select.options.selectedIndex].attributes['data-post'].value

    $('.event-item_'+post_id).removeClass('active');
    $('.select-city option[value=0]').prop('selected', 'selected');
    $('.select-city option[data-place="'+place_id+'"]').prop('selected', 'selected')
    if (! post_id) return -1;

    $('.event_'+place_id+'_'+post_id).addClass('active');
  }

  $('.select-city').on("change", showEvents);

})(document, window, jQuery);

/**
 * Load EventBrite iFrames when a modal is opened
 */
(function (document, $) {
  $(window).on('cannaworks.modal.open', function (event) {
    var target = event.target;
    if (target.id.substr(0, 3) !== 'eb_') return 0;
    var eId = target.id.substr(3);
    if (!eId) return 0;

    var content = target.querySelector('.eb-content');
    if (!content || content.querySelector('iframe')) return 0;

    var iframe = document.createElement('IFRAME');
    iframe.src = 'https://eventbrite.com/checkout-external?eid='+ eId +'&parent=https://cannaworkspr.com/certificacion/';
    iframe.dataset.automation = "checkout-widget-iframe-" + eId
    iframe.frameborder = "0";
    iframe.allowtransparency = true;
    iframe.scrolling = "auto";
    iframe.height = "430";
    iframe.width = "100%";
    iframe.vspace = "0";
    iframe.hspace = "0";
    iframe.marginheight = "5";
    iframe.marginwidth = "5";

    content.appendChild(iframe);
  })
})(document, jQuery)

/* Back to top */

jQuery("#backtotop").click(function () {
  jQuery("body,html").animate({
      scrollTop: 0
  }, 600);
});
jQuery(window).scroll(function () {
  if (jQuery(window).scrollTop() > 150) {
      jQuery("#backtotop").addClass("visible");
  } else {
      jQuery("#backtotop").removeClass("visible");
  }
});


(function (document, window, $) {

  var cookies = document.querySelector('.cookies');
  var cookiesButton = document.querySelector('.button--cookies');
  var cookiesLocalStorage

  if (! cookies) return -1;

  cookiesLocalStorage = localStorage.getItem("cookies-pr")
  if (cookiesLocalStorage) {
    cookies.classList.add('close');
  }

  var closeCookies = function (event) {
    event.stopPropagation();
    event.preventDefault();

    cookies.classList.add('close');
    localStorage.setItem("cookies-pr", 'yes');
  };

  cookiesButton.addEventListener('click', closeCookies);

})(document, window, jQuery);
